@import '../styles/variables';

$size: 65px;

.notificationBadge {
  color: #fff;
  font-weight: bold;
  border-radius: 100px;
  position: absolute;
  top: calc(50% - 30px);
  left: 7px;
  height: 22px;
  width: 22px;
  display: block;
  line-height: 20px;
  margin-left: 34px;
  font-size: 11px;
  z-index: 1000;
  text-align: center;
  background-image: linear-gradient(115deg, #ff5689, #e687ff);
}

.wrapper {
  height: $footer-height;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  display: flex;
  transition: height 500ms;
  box-shadow: 0px -2px 11px 0px rgba(215, 215, 215, 0.69);
  line-height: initial;
  &.column {
    width: $size;
    height: 100vh;
    top: 0;
    bottom: initial;
    flex-direction: column;
    overflow-x: hidden;
    transition: width 500ms;
  }
  .navRow {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
    padding-right: 20px;
    overflow-y: scroll;
    &.column {
      flex-direction: column;
      height: 90%;
      width: $size;
    }
    &.expanded {
      align-items: flex-end;
      flex-wrap: wrap;
    }
    &.children {
      > span {
        height: $footer-height;
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
    }
    .button {
      height: 48px;
      width: 48px;
      display: flex;
      align-items: center;
      flex-direction: column;
      &.column {
        display: flex;
        width: 48px;
        justify-content: center;
        height: 20%;
        padding: 0;
        max-height: 100px;
        svg {
          transform: unset;
          position: initial;
        }
        .notificationBadge {
          top: calc(50% - 33px);
          left: 0;
        }
        .iconLabel {
          text-align: center;
        }
      }
      .iconLabel {
        font-size: 60%;
        @media (max-width: 340px) {
          font-size: 70%;
        }
        margin-top: 8px;
      }
    }
  }
}

.confirmWrapper {
  width: 100vw;
  z-index: 90000;
  transform: translateX(0px);
}
